.album-cover  {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    width: 90%;
    height: 100vh;
  }

  &.reversed {
    flex-direction: column;

    @media (min-width: 1024px) {
      flex-direction: row-reverse;
    }
  }

  .cover-abstract {
    display: none;
    position: absolute;
    top: 8%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: .1;

    .abstract-figure {
      border-radius: 100%;
      position: absolute;
      animation: figure-animation 10s infinite;

      @keyframes figure-animation {
        from {
          margin-top: 0;
        }

        40% {
          margin-top: -2%;
        }

        60% {
          margin-top: 0;
        }

        to {
          margin-top: 0;
        }
      }

      &.one {
        width: 7vw;
        height: 7vw;
        top: 45%;
        left: 63%;
        background-color: #b9936c;
      }

      &.two {
        width: 18vw;
        height: 18vw;
        top: 3%;
        left: 40%;
        background-color: #c1946a;
      }

      &.three {
        width: 2vw;
        height: 2vw;
        top: 20%;
        left: 90%;
        background-color: #e6e2d3;
      }
    }

    &.reversed {
      .one {
        width: 8vw;
        height: 8vw;
        top: 45%;
        left: 35%;
        background-color: #b9936c;
      }

      .two {
        width: 14vw;
        height: 14vw;
        top: 8%;
        left: 0%;
        background-color: #c4b7a6;
      }

      .three {
        display: none;
      }
    }

    @media (min-width: 1024px) {
      display: block;
    }
  }
}
