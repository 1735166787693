.contacts {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-size: cover;

  .contacts-inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    .phone {
      opacity: 0;
      font-size: 2.2rem;
      margin-bottom: 10%;
      transition: all 2s ease-in-out;

      @media (min-width: 768px) {
        margin-bottom: 7%;
      }
    }

    .socials {
      display: flex;

      .social-icon {
        opacity: 0;
        transition: all 2s ease-in-out;

        a {
          margin: 0 10px;
          display: block;

          img {
            height: 40px;
            display: block;
    
            @media (min-width: 768px) {
              height: 40px;
            }
          }
        }
      }
    }

    &.animated {
      .phone {
        opacity: 1;
      }

      .social-icon {
        opacity: 1;

        &:nth-child(1) {
          transition-delay: 1s;
        }

        &:nth-child(2) {
          transition-delay: 2s;
        }

        &:nth-child(3) {
          transition-delay: 3s;
        }
      }
    }
  }
}
