.album {
  display: flex;
  width: 100%;
  height: 100vh;
}

.album .yarl__slide {
  padding: 0;
}

.album .yarl__button {
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
}
