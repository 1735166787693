.price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: auto;
  position: relative;
  overflow: hidden;
  padding: 120px 0;

  @media (min-width: 1024px) {
    min-height: 100vh;
  }

  .abstract-container {
    position: absolute;
    width: 140vw;
    height: 100vw;
    top: -20%;
    left: 0%;
    transform: rotate(30deg);
    background: rgba(185,147,108, .1);
    z-index: 1;
    animation: abstract-container 5s infinite;

    @keyframes abstract-container {
      from {
        top: -20%;
      }

      40% {
        top: -22%;
      }

      80% {
        top: -20%;
      }

      to {
        top: -20%;
      }
    }

    @media (min-width: 768px) {
      width: 140vw;
      height: 100vw;
      top: -60%;
      left: 0%;

      @keyframes abstract-container {
        from {
          top: -60%;
        }
  
        40% {
          top: -62%;
        }
  
        80% {
          top: -60%;
        }
  
        to {
          top: -60%;
        }
      }
    }

    @media (min-width: 1024px) {
      width: 100vw;
      height: 100vw;
      top: -120%;
      left: 10%;

      @keyframes abstract-container {
        from {
          top: -120%;
        }
  
        40% {
          top: -122%;
        }
  
        80% {
          top: -120%;
        }
  
        to {
          top: -120%;
        }
      }
    }
  }

  span {
    font-size: 2rem;
  }

  .price-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    z-index: 2;

    .final-price {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 3.5rem;
      line-height: .9;
      top: 0;
      left: 0;
      margin-bottom: 5%;

      span {
        font-size: 1.4rem;
      }
    }

    .select-service-container {
      display: flex;
      flex-direction: column;
      width: 80%;

      @media (min-width: 768px) {
        width: 80%;
      }

      @media (min-width: 1024px) {
        width: 60%;
      }
    }

    .order-btn {
      button {
        width: 200px;
        height: 70px;
        border: 2px solid white;
        border-radius: 35px;
        color: #fff;
        background-color: transparent;
        cursor: pointer;
        font-size: 1rem;
        margin-top: 50px;
        transition: all .5s ease-in-out;
        animation: order-btn 10s infinite;

        @keyframes order-btn {
          from {
            margin-left: 0;
          }

          1% {
            margin-left: -10px;
          }

          2% {
            margin-left: 0;
          }

          3% {
            margin-left: -10px;
          }

          4% {
            margin-left: 0px;
          }

          5% {
            margin-left: -10px;
          }

          6% {
            margin-left: 0px;
          }

          to {
            margin-left: 0px;
          }
        }

        &:hover {
          background-color: rgba(0, 0, 0, .3);
        }
      }
    }
  }
}
