.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10%;
  width: 100%;
  min-height: 100vh;
  color: #fff;
  position: relative;
  overflow: hidden;

  .abstract-container {
    position: absolute;
    width: 200vw;
    height: 200vw;
    top: -50%;
    left: -30%;
    transform: rotate(30deg);
    background: rgba(185,147,108, .1);
    z-index: 1;
    animation: nf-abstract-container 5s infinite;

    @keyframes nf-abstract-container {
      from {
        top: -50%;
      }

      40% {
        top: -52%;
      }

      80% {
        top: -50%;
      }

      to {
        top: -50%;
      }
    }

    @media (min-width: 768px) {
      top: -80%;
      left: -20%;

      @keyframes nf-abstract-container {
        from {
          top: -80%;
        }
  
        40% {
          top: -82%;
        }
  
        80% {
          top: -80%;
        }
  
        to {
          top: -80%;
        }
      }
    }

    @media (min-width: 1024px) {
      width: 100vw;
      height: 100vw;
      top: -120%;
      left: 10%;

      @keyframes nf-abstract-container {
        from {
          top: -120%;
        }
  
        40% {
          top: -122%;
        }
  
        80% {
          top: -120%;
        }
  
        to {
          top: -120%;
        }
      }
    }
  }

  h2 {
    position: relative;
    z-index: 2;
  }

  button {
    width: 250px;
    height: 70px;
    border: 2px solid white;
    border-radius: 35px;
    color: #fff;
    background-color: transparent;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 50px;
    transition: all .5s ease-in-out;
    position: relative;
    z-index: 2;

    &:hover {
      background-color: rgba(0, 0, 0, .3);
    }
  }
}
