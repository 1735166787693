.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100vh;

  video {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
  }

  .content {
    z-index: 1;

    .first-title {
      animation: first-title 15s infinite;

      @keyframes first-title {
        from {
          margin-left: 50px;
        }

        20% {
          margin-left: 60px;
        }

        40% {
          margin-left: 50px;
        }

        to {
          margin-left: 50px;
        }
      }

      @media (min-width: 768px) {
        @keyframes first-title {
          from {
            margin-left: 100px;
          }
      
          20% {
            margin-left: 110px;
          }
      
          40% {
            margin-left: 100px;
          }
      
          to {
            margin-left: 100px;
          }
        }
      }

      @media (min-width: 1024px) {
        @keyframes first-title {
          from {
            margin-left: 10%;
          }
      
          20% {
            margin-left: 11%;
          }
      
          40% {
            margin-left: 10%;
          }
      
          to {
            margin-left: 10%;
          }
        }
      }
    }

    .second-title {
      animation: second-title 15s infinite;

      @keyframes second-title {
        from {
          margin-left: 50px;
        }

        20% {
          margin-left: 45px;
        }

        40% {
          margin-left: 50px;
        }

        to {
          margin-left: 50px;
        }
      }

      @media (min-width: 768px) {
        @keyframes second-title {
          from {
            margin-left: 100px;
          }
      
          20% {
            margin-left: 95px;
          }
      
          40% {
            margin-left: 100px;
          }
      
          to {
            margin-left: 100px;
          }
        }
      }

      @media (min-width: 1024px) {
        @keyframes second-title {
          from {
            margin-left: 10%;
          }
      
          20% {
            margin-left: 9.5%;
          }
      
          40% {
            margin-left: 10%;
          }
      
          to {
            margin-left: 10%;
          }
        }
      }
    }
  }

  .scroll-down {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border: 2px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    bottom: 30px;
    left: 30px;
    animation: scroll-down-animation 10s infinite;

    svg {
      width: 20px;
      color: white;
    }

    @keyframes scroll-down-animation {
      from {
        bottom: 30px;
      }
  
      3% {
        bottom: 24px;
      }
  
      6% {
        bottom: 30px;
      }
  
      8% {
        bottom: 24px;
      }
  
      10% {
        bottom: 30px;
      }
    }
  }
}
