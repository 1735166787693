.cover-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    margin: 20px 0 100px 0;
    position: relative;
    z-index: 2;

    @media (min-width: 768px) {
        width: 70%;
        margin: 40px 0 120px 0;
    }

    @media (min-width: 1024px) {
        height: 100%;
        width: 50%;
        margin: 0 5%;
    }

    .description-container {
        position: relative;

        .description-bg {
            display: block;
            visibility: hidden;
        }

        .description {
            position: absolute;
            top: 30%;
            opacity: 0;
            transition: all 1s ease-in-out;

            &.visible {
                top: 0;
                opacity: 1;
            }

            a {
                color: #fff;
            }
        }
    }
}
