* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 18px;
  }

  @media (min-width: 1024px) {
    font-size: 19px;
  }

  @media (min-width: 1280px) {
    font-size: 20px;
  }
}

h1 {
  font-size: 2.8rem;
  line-height: 1;
}

h2 {
  font-size: 2rem;
}

body {
  font-family: 'Philosopher', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background: #272727;
  color: #fff;
  display: flex;
  min-height: 100vh;
}

input, select, button{
  font-family: Arial;
}

a {
  text-decoration: none;
}

#root {
  width: 100%;
}
