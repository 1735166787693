.portfolio {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 130px;

    @media (min-width: 1024px) {
        margin-top: 0;
    }
}
