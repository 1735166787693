.banner {
  position: fixed;
  bottom: 32px;
  right: 25px;
  display: flex;
  z-index: 15;

  .banner-inner {
    display: flex;
    flex-direction: column;
    width: 100px;
    height: 55px;

    .banner-part {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.top {
        background-color: blue;
        color: yellow;
      }

      &.bottom {
        background-color: yellow;
        color: blue;
      }

      h1 {
        font-size: 0.55rem;
      }
    }
  }
}