.price-item {
  display: flex;
  align-items: center;
  margin: 3% 0;

  @media (min-width: 768px) {
    margin: 2% 0;
  }

  @media (min-width: 1024px) {
    margin: 1%;
  }

  .title {
    font-size: 1.5rem;
    margin-left: 3%;

    @media (min-width: 768px) {
      font-size: 2rem;
    }

    @media (min-width: 1024px) {
      font-size: 1.7rem;
    }
  }
}
