.cover-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0;
    position: relative;
    z-index: 2;

    @media (min-width: 768px) {
        width: 80%;
    }

    @media (min-width: 1024px) {
        height: 100%;
        width: 50%;
        margin: 0 5%;
    }

    .image-container {
        display: flex;
        width: 100%;
        background-size: cover;
        overflow: hidden;
        position: relative;

        img {
            &.album-cover-bg {
                width: 100%;
                display: block;
                visibility: hidden;
            }

            &.front-cover {
                opacity: 1;
                transition: all 1s ease-in-out;

                &:hover {
                    opacity: 0;
                }
            }
        }

        .image-blink {
            width: 100%;
            height: 100vw;
            top: -200%;
            left: -100%;
            position: absolute;
            transform: rotate(45deg);
            opacity: .2;
            background-image: linear-gradient(to right, transparent 0%, #fff 50%, transparent 100%);
            transition: all .5s;
            z-index: 3;
            display: none;

            @media (min-width: 1024px) {
                display: block;
            }
        }

        &:hover {
            .image-blink {
                left: 200%;
            }
        }

        .album-front-bg {
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 4;
        }
    }
}
