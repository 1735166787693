.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .title {
    font-size: 1rem;
    margin: 150px 0 20px;
    width: 100%;
    padding: 0 40px;

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }

    @media (min-width: 1024px) {
      font-size: 1rem;
      margin: 100px 0 10px;
      width: 55%;
      padding: 0;
    }
  }

  .presentation {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    $width: 100%;
    width: $width;
    padding-bottom: ($width * 0.55);
  
    @media (min-width: 1024px) {
      $width: 55%;
      width: $width;
      padding-bottom: ($width * 0.55);
    }

    video {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }

    .play {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 110px;
      background: transparent;
      cursor: pointer;
      border: 3px solid #fff;
      border-radius: 50%;
      transition: all .7s ease-in-out;

      .btn {
        width: 35px;
        height: 35px;
        color: #fff;
        transition: all .7s ease-in-out;
      }

      &:hover {
        background: rgba(0, 0, 0, .5);
      }
    }
  }

  .description {
    margin: 70px 0 0 0;

    @media (min-width: 1024px) {
      margin: 5% 0;
    }

    button {
      width: 200px;
      height: 70px;
      border: 2px solid white;
      border-radius: 35px;
      color: #fff;
      background-color: transparent;
      cursor: pointer;
      font-size: 1rem;
      transition: all .5s ease-in-out;
      animation: learn-btn 10s infinite;

      @keyframes learn-btn {
        from {
          margin-left: 0;
        }

        1% {
          margin-left: -10px;
        }

        2% {
          margin-left: 0;
        }

        3% {
          margin-left: -10px;
        }

        4% {
          margin-left: 0px;
        }

        5% {
          margin-left: -10px;
        }

        6% {
          margin-left: 0px;
        }

        to {
          margin-left: 0px;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, .3);
      }
    }
  }
}
