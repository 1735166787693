.menu-item {
    display: flex;
    justify-content: center;
    margin: 6px 0;

    .link {
        color: #cdcbcb;
        font-size: 2.5rem;
        cursor: pointer;
        text-decoration: none;
        transition: all .4s;

        &:hover {
            color: #fff;
        }
    }
}
