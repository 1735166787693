.menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    background: rgb(0, 0, 0, .95);
    transition: all .5s ease-in-out;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.menu.open {
    opacity: 1;
    visibility: visible;
}
